import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Intercambio } from '../../interfaces/Intercambio';

@Injectable({
	providedIn: 'root'
})
export class MindtechApiService {

	constructor(public http: HttpClient) { }

	sendEmail(intercambio: Intercambio) {
		const url = environment.api + "/sendEmail.php";
		return this.http.post(url, { intercambio: intercambio.participantes, fecha: intercambio.fecha, nombre_intercambio: intercambio.nombre });
	}

	sendEmailOnEdit(persona: any, fecha: string, nombre_intercambio: string) {
		const url = environment.api + "/sendEmailOnEdit.php";
		return this.http.post(url, { email: persona.email, celular: persona.celular, nombre: persona.nombre, tetoca: persona.tetoca, fecha: fecha, nombre_intercambio: nombre_intercambio });
	}

	resendSecretFriend(persona: any, fecha: string, nombre_intercambio: string) {
		const url = environment.api + "/resendSecretFriend.php";
		return this.http.post(url, { email: persona.email, celular: persona.celular, nombre: persona.nombre, tetoca: persona.tetoca, fecha: fecha, nombre_intercambio: nombre_intercambio });
	}

	sendFeedback(feedback: string){
		const url = environment.api + "/sendFeedback.php";
		return this.http.post(url, { feedback: feedback });
	}

	searchMercadolibre(busqueda: string, page: number) {
		const url = environment.api + `/mlibre_search.php?busqueda=${busqueda}&page=${page}`;
		return this.http.get(url);
	}

	searchAmazon(busqueda: string, page: number) {
		const url = environment.api + `/amazon_search.php?busqueda=${busqueda}&page=${page}`;
		return this.http.get(url);
	}

	getItem(item_id: string) {
		const url = environment.api + `/mlibre_item.php?item_id=${item_id}`;
		return this.http.get(url);
	}

	sendVerificationCode(userId, phoneNumber){
		const url = environment.api + "/sendSMSVerificationCode.php";
		return this.http.post(url, { userId, phoneNumber });
	}

	verifyCode(userId, phoneNumber, inputCode){
		const url = environment.api + "/verifySMSCode.php";
		return this.http.post(url, { userId, phoneNumber, inputCode});
	}
}
