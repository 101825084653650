import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MindtechApiService } from '../shared/services/mindtech-api/mindtech-api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ItemDetailPageRoutingModule } from './item-detail-routing.module';
import { register } from 'swiper/element/bundle';
import { Subscription } from 'rxjs';
register

@Component({
	selector: 'app-item-detail',
	templateUrl: './item-detail.page.html',
	standalone: true,
	imports: [CommonModule,
		FormsModule,
		IonicModule],
	styleUrls: ['./item-detail.page.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ItemDetailPage implements OnInit {


	item = {
		title: "",
		price: 175,
		permalink: "",
		thumbnail: "",
		description: "",
		pictures: []
	};

	selectedPictureURL = "";
	routeSubscription_: Subscription;
	getItemSubscription_: Subscription;

	constructor(private mindtechSrv: MindtechApiService, private route: ActivatedRoute) {
		this.routeSubscription_ = this.route.params.subscribe(params => {
			const itemId = params.id;
			this.getItemDetail(itemId);
		});
	}

	ngOnInit() {

	}

	ngOnDestroy(): void {
		this.routeSubscription_ ? this.routeSubscription_.unsubscribe() : null;
		this.getItemSubscription_ ? this.getItemSubscription_.unsubscribe() : null;
	}


	selectImage(num) {
		console.info("Seleccionando imagen");
	}

	getItemDetail(itemId) {
		this.getItemSubscription_ = this.mindtechSrv.getItem(itemId).subscribe((data: any) => {
			this.item = data.item;
			this.selectedPictureURL = this.item.pictures[0].secure_url;
		});
	}

	getNumber(num: number) {
		return Math.floor(num).toLocaleString('en-US');
	}

	getCents(num: number) {
		const cents = Math.round((num - Math.floor(num)) * 100);
		return cents === 0 ? '00' : `${cents.toString().padStart(2, '0')}`;
	}
}
