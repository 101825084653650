import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ItemDetailPage } from './item-detail/item-detail.page';
import { AmazonItemDetailPage } from './amazon-item-detail/amazon-item-detail.page';
import { WishListPage } from './wish-list/wish-list.page';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'folder/:id',
		loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'intercambio/:id',
		loadChildren: () => import('./intercambio/intercambio.module').then(m => m.IntercambioPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'wish-list-home',
		loadChildren: () => import('./wish-list-home/wish-list-home.module').then(m => m.WishListHomePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'crear-intercambio',
		loadChildren: () => import('./crear-intercambio/crear-intercambio.module').then(m => m.CrearIntercambioPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'wishlist/:id/:from',
		component: WishListPage
	},
	{
		path: 'feedback',
		loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'about-us',
		loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule),
	},
	{
		path: 'profile',
		loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'search-mlibre',
		loadChildren: () => import('./search-mlibre/search-mlibre.module').then(m => m.SearchMlibrePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'item-detail/:id',
		component: ItemDetailPage,
		canActivate: [AuthGuard]
	},
	{
		path: 'search-amazon/:wish_list_id',
		loadChildren: () => import('./search-amazon/search-amazon.module').then(m => m.SearchAmazonPageModule)
	},
	{
		path: 'amazon-item-detail',
		component: AmazonItemDetailPage,
		canActivate: [AuthGuard]
	}

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
