import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '../../interfaces/User';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private firestore: AngularFirestore) { }

	createUser(user: User) {
		return this.firestore.collection('Users').doc(user.uid).set(user);
	}

	getUserbyEmail(email: string) : Observable<any[]> {
		return this.firestore.collection('Users', ref => ref.where('email', '==', email)).valueChanges();
	}

	deleteUserByEmail(email: string){
		return this.firestore.collection('Users', ref => ref.where('email', '==', email)).get();
	}

	updateUser(user: User){
		let doc = this.firestore.collection("Users").doc(user.uid);

		doc.update(user)
		.then(() => {
			console.info("Document successfully updated!");
		})
		.catch((error) => {
			console.error("Error updating document: ", error);
		});
	}

	// setUsersTutorialFalse(){
	// 	this.firestore.collection('Users').valueChanges().subscribe(data=>{
	// 		data.forEach((user:any) => {
	// 			user.tutorial = true;
	// 			this.updateUser(user);
	// 		});
	// 	})
	// }

}
