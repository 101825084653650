import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonicModule, IonItemSliding, IonList, IonModal, ModalController, Platform } from '@ionic/angular';
import { Wish, WishList } from 'src/app/shared/interfaces/Wishlist';
import { UtilsService } from 'src/app/shared/services/Utils/Utils.service';
import { WishListService } from '../shared/services/wish-list/wish-list.service';
import { OverlayEventDetail } from '@ionic/core/components';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Share } from '@capacitor/share';


@Component({
	selector: 'app-wish-list',
	templateUrl: './wish-list.page.html',
	styleUrls: ['./wish-list.page.scss'],
	standalone: true,
	imports: [CommonModule,
		FormsModule,
		IonicModule]
})
export class WishListPage implements OnInit, OnDestroy {

	// Subscriptions
	private ogDataAmazonSub_: Subscription;
	private wishListSub_ : Subscription;
	private ogDataMercadoLibreSub_: Subscription;
	private ogDataElektraSub_: Subscription;
	private ogDataOtherSub_: Subscription;
	private routeSubscription_: Subscription;

	// @ViewChild('lista') lista: IonItemSliding;
	@ViewChildren(IonItemSliding) slidingItems!: QueryList<IonItemSliding>;


	public link: string;
	public refLink: string;
	public wishList: WishList;
	public wishes: any[];
	public from: string;
	public ownWishList: boolean;
	public editing: boolean = false;
	public shaking: boolean = false;
	public isthere: boolean = false;

	public wishData: Wish = {
		title: '',
		description: '',
		image: 'assets/storeLogos/other.jpg',
		link: '',
		price: ''
	}

	public stores: string[] = [
		'amazon',
		'mercadolibre',
		'liverpool',
		'coppel',
		'elektra',
		'alibaba',
		'aliexpress',
		'walmart',
		'gandhi'
	]

	constructor(
		private wishService: WishListService,
		public firestore: AngularFirestore,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private utils: UtilsService,
		private modalCtrl: ModalController,
		public platform: Platform,
		public nav: Router) {

		this.wishList = {
			nombre: ''
		};
		this.ownWishList = false;
		console.log("Entrando a Wish list page");

	}
	ngOnDestroy(): void {
		this.ogDataAmazonSub_ ? this.ogDataAmazonSub_.unsubscribe() : '';
		this.wishListSub_ ? this.wishListSub_.unsubscribe() : '';
		this.ogDataMercadoLibreSub_ ? this.ogDataMercadoLibreSub_.unsubscribe() : '';
		this.ogDataElektraSub_ ? this.ogDataElektraSub_.unsubscribe() : '';
		this.ogDataOtherSub_ ? this.ogDataOtherSub_.unsubscribe() : '';
		this.routeSubscription_ ? this.routeSubscription_.unsubscribe() : '';
	}

	ngOnInit() {
		console.log("Ejecutando");
		this.routeSubscription_ = this.route.params.subscribe(data => {

			this.from = data.from;
			console.log(this.from);
			this.wishListSub_ = this.wishService.getList(data.id).subscribe((wishlist: WishList) => {
				console.log(wishlist);
				if(wishlist){
					if (wishlist.email === localStorage.getItem('email')) {
						this.ownWishList = true;
					}
					this.wishList = wishlist;
					this.wishService.selectedWishList = wishlist;
					this.wishList.id = data.id;

					const wishesPromises = this.wishList.wishes.map(element => {
						return element.get().then(data => {
						  const item = data.data();
						  item.id = data.id;
						  return item;
						});
					  });


					Promise.all(wishesPromises)
						.then(wishes => {
						this.wishes = wishes;
						if (this.wishes.length > 0) {
							this.isthere = true;
						} else {
							this.isthere = false;
						}
						})
						.catch(error => {
							console.error("Error al obtener deseos:", error);
						});
				}

			});
		});
	}

	addLink() {
		this.lookForWish2();
	}

	lookForWish() {
		this.utils.showLoading();
		if (this.link.includes('amazon.com')) {

			this.refLink = this.link.split('dp/')[1];
			this.refLink = this.refLink.split('?')[0];
			this.refLink = 'https://amazon.com.mx/dp/' + this.refLink + '/ref=nosim?tag=wish-box-20';

			this.wishService.lookForWish(this.refLink).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);
				} else {
					this.amazonLink().then(data=>{

					})
				}
			})
		} else if (this.link.includes('mercadolibre.com')) {
			this.wishService.lookForWish(this.link).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.mercadoLibreLink().then(data=>{

					})
				}
			})
		} else if (this.link.includes('elektra.com')) {
			this.wishService.lookForWish(this.link).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.elektraLink().then(data=>{

					})
				}
			})
		} else if (this.foundStore(this.link)) {
			this.wishService.lookForWish(this.link).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.otherLink().then(data=>{

					})
				}
			})
		} else {
			this.utils.hideLoading();
			this.utils.alertError('Tienda no agregada','Al parecer el link de la tienda que quieres agregar no esta en nuestra base de datos, por favor solicita que la agreguen dando clien en ok');

		}
	}

	lookForWish2() {
		this.utils.showLoading();

		if (this.isAmazonLink(this.link)) {
			const link = this.generateAffiliateLink(this.link);

			this.wishService.lookForWish(link).toPromise().then(data => {

				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.amazonLink().then(data=>{


					})
				}



			})
		} else if (this.link.includes('mercadolibre.com')) {
			this.wishService.lookForWish(this.link).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.mercadoLibreLink().then(data=>{

					})
				}
			})
		} else if (this.link.includes('elektra.com')) {
			this.wishService.lookForWish(this.link).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.elektraLink().then(data=>{

					})
				}
			})
		} else if (this.foundStore(this.link)) {
			this.wishService.lookForWish(this.link).toPromise().then(data => {
				if (data.docs.length > 0) {
					this.updateWishList(data.docs[0].id);

				} else {
					this.otherLink().then(data=>{

					})
				}
			})
		} else {
			this.utils.hideLoading();
			this.utils.alertError('Tienda no agregada','Al parecer el link de la tienda que quieres agregar no esta en nuestra base de datos, por favor solicita que la agreguen dando clien en ok');

		}
	}


	async amazonLink() {
		this.ogDataAmazonSub_ = this.wishService.getOgDataAmazon(this.link).subscribe((data: any) => {
			data.success.link = this.link;
			this.wishData.description = data.success.description;
			this.wishData.image = data.success.image;
			this.wishData.link = this.generateAffiliateLink(data.success.link);
			this.wishData.title = data.success.title;

			// this.showWishModal();
			this.utils.hideLoading();
		});
	}

	async mercadoLibreLink() {
		this.ogDataMercadoLibreSub_ = this.wishService.getOgDataMercadoLibre(this.link).subscribe((data: any) => {
			const wish: Wish = {
				title: data.success.title,
				description: data.success.description,
				image: data.success.image,
				link: this.link
			}
			this.wishService.createWish(wish).then(data => {
				this.updateWishList(data.id);
				this.utils.hideLoading();
			})
		});
	}


	async elektraLink() {
		this.ogDataElektraSub_ = this.wishService.getOgDataElektra(this.link).subscribe((data: any) => {
			const wish: Wish = {
				title: data.success.title,
				description: data.success.description,
				image: data.success.image,
				link: this.link
			}
			this.wishService.createWish(wish).then(data => {
				this.updateWishList(data.id);
				this.utils.hideLoading();
			})
		});

	}

	async otherLink() {
		this.ogDataOtherSub_ = this.wishService.getOgDataOther(this.link).subscribe((data: any) => {
			const wish: Wish = {
				title: '',
				description: '',
				image: '',
				link: this.link
			}

			if (data.success.title === 'Access Denied' || data.success.title === '') {
				const store = this.foundStore(this.link);

				switch (store) {
					case 'liverpool':
						wish.title = '';
						wish.title = this.link.split('pdp/')[1];
						wish.title = wish.title.split('/')[0];
						wish.title = wish.title.replace('-', ' ');
						wish.image = 'assets/storeLogos/liverpool.png';
						wish.link = this.link;
						this.wishService.createWish(wish).then(data => {
							this.updateWishList(data.id);
							this.utils.hideLoading();
						})
						break;
					case 'coppel':
						wish.title = '';
						wish.title = this.link.split('ppel.com/')[1];
						wish.title = wish.title.split('-pr-')[0];
						wish.title = wish.title.replace('-', ' ');
						wish.image = 'assets/storeLogos/coppel.png';
						wish.link = this.link;
						this.wishService.createWish(wish).then(data => {
							this.updateWishList(data.id);
							this.utils.hideLoading();
						})
						break;

					default:
						wish.title = data.success.title;
						wish.image = 'assets/storeLogos/other.jpg';
						wish.link = this.link;
						this.alertArticleName().then((alert) => {
							alert.present();

							alert.onDidDismiss().then((data: any) => {
								wish.title = data.data.nombre;
								this.wishService.createWish(wish).then(data => {
									this.updateWishList(data.id);
									this.utils.hideLoading();
								})
							});
						});
						break;
				}
			} else {
				wish.title = data.success.title;
				wish.description = data.success.description;
				wish.image = data.success.image;
				wish.link = this.link;

				this.wishService.createWish(wish).then(data => {
					this.updateWishList(data.id);
					this.utils.hideLoading();
				})
			}
		});
	}

	updateWishList(wishId: string) {
		this.wishList.wishes.push(this.firestore.doc('Wishes/' + wishId).ref);
		this.firestore.doc('WishLists/' + this.wishList.id).update(this.wishList);
	}

	deleteWishFromList(wish) {
		this.wishes = this.wishes.filter(deseo => deseo.id !== wish.id);

		this.wishList.wishes = this.wishList.wishes.filter(deseo => {
			if (deseo.id != wish.id) {
				return deseo;
			}
		});

		this.firestore.doc('WishLists/' + this.wishList.id).update(this.wishList);

	}

	cutTitle(title: string) {
		return title.substring(0, 30);
	}

	deleteWish(wish) {
		this.alertDelete(wish);
	}

	async alertDelete(wish) {
		const alert = await this.alertCtrl.create({
			cssClass: 'my-custom-class',
			header: 'Borrar',
			subHeader: 'Borrar item' + wish.title,
			message: 'Estas seguro de borrar esto de la lista de deseos.',
			buttons: [{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'secondary',
				handler: (blah) => {
					console.info('Confirm Cancel: blah');
				}
			},
			{
				text: 'Borrar',
				role: 'borrar',
				cssClass: 'danger',
				handler: (blah) => {
					this.deleteWishFromList(wish);
				}
			}]
		});

		await alert.present();

		const { role } = await alert.onDidDismiss();
	}

	foundStore(link: string): string {
		return this.stores.find(store => {
			return link.includes(store);
		});
	}

	async alertArticleName() {

		const alert = this.alertCtrl.create({
			cssClass: 'my-custom-class',
			header: 'Agrega su nombre',
			inputs: [
				{
					name: 'nombre',
					type: 'text',
					placeholder: 'Nombre del articulo',
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => { },
				},
				{
					text: 'Ok',
					handler: (data) => {
						return data;
					},
				},
			],
		});
		return alert;
	}










	// cancel() {
	//   this.wishModal.dismiss(null, 'cancel');
	//   this.link = '';
	//   this.wishData =  {
	// 	title:'',
	// 	description:'',
	// 	image: 'assets/storeLogos/other.jpg',
	// 	link: '',
	// }
	// }

	// showWishModal(){
	// 	this.wishModal.present();
	// }

	onWillDismiss(event: Event) {
		this.link = '';
		this.wishData =  {
			title:'',
			description:'',
			image: 'assets/storeLogos/other.jpg',
			link: '',
		}
	}


	// Last functions
	saveWish(wishData){
		this.wishData = wishData;
		this.wishService.createWish(this.wishData).then(data => {
			this.updateWishList(data.id);
			this.utils.hideLoading();
			// this.wishModal.dismiss();
		})
	}

	searchLink(link){
		this.link = link;
		this.lookForWish2();
	}

	isThereAny(){
		if(this.wishes.length < 1){
			this.isthere = false;
		} else{
			this.isthere = true;
		}
	}

	changeEdit(){
			if(this.editing){
				this.editing = false;
				this.shaking = false;
				this.closeAllSlidingItems();
			}else {
				this.editing = true;
				if(!this.platform.platforms().includes('ios')){
					this.shaking = true;
				}
				this.openAllSlidingItems();
			}
	}

 	generateAffiliateLink(productUrl) {
		// Crear un objeto URL a partir de la URL del producto
		const url = new URL(productUrl);

		// Establecer el 'tag' al parámetro de búsqueda con tu Associate Tag
		url.searchParams.set('tag', 'wish-box-20');

		// Devolver la URL modificada como un string
		return url.href;
	  }

	  isAmazonLink(url) {
		try {
		  // Crear un objeto URL a partir de la URL proporcionada
		  const parsedUrl = new URL(url);

		  // Verificar si el dominio es de Amazon
		  return parsedUrl.hostname.endsWith('amazon.com') || // Para Amazon EE.UU.
				 parsedUrl.hostname.endsWith('amazon.co.uk') || // Para Amazon Reino Unido
				 parsedUrl.hostname.endsWith('amazon.de') || // Para Amazon Alemania
				 parsedUrl.hostname.endsWith('amazon.ca') || // Para Amazon Canadá
				 parsedUrl.hostname.endsWith('amazon.in') || // Para Amazon India
				 parsedUrl.hostname.endsWith('amazon.com.br') || // Para Amazon Brasil
				 parsedUrl.hostname.endsWith('amazon.co.jp') || // Para Amazon Japón
				 parsedUrl.hostname.endsWith('amazon.it') || // Para Amazon Italia
				 parsedUrl.hostname.endsWith('amazon.es') || // Para Amazon España
				 parsedUrl.hostname.endsWith('amazon.fr') || // Para Amazon Francia
				 parsedUrl.hostname.endsWith('amazon.cn') || // Para Amazon China
				 parsedUrl.hostname.endsWith('amazon.nl')|| // Para Amazon Países Bajos
				 parsedUrl.hostname.endsWith('amzn.to') || // Para Amazon Países Bajos
				 parsedUrl.hostname.endsWith('a.co');  // Para Amazon Países Bajos
		} catch (error) {
		  // Si la URL es inválida o si hay algún error, asumir que no es un enlace de Amazon
		  return false;
		}
	  }

	goToSearchGifts(){
		this.nav.navigateByUrl(`/search-amazon/${this.wishList.id}`);
	}

	openAllSlidingItems() {
		this.slidingItems.forEach((slidingItem) => {
			slidingItem.open('end');  // Ejemplo: cerrar todos los sliders
		  });
	}

	closeAllSlidingItems() {
		this.slidingItems.forEach((slidingItem) => {
			slidingItem.close();  // Ejemplo: cerrar todos los sliders
		  });
		this.firestore.doc('WishLists/' + this.wishList.id).update(this.wishList);
	}


	async shareList() {
		try {
			await Share.share({
			title: 'Wishbox',
			text: 'Mira mi lista de regalos en WishBox:',
			url: `http://app.wish-box.com.mx/wish-list/${this.wishList.id}/web`,
			dialogTitle: 'Compartir con'
			});
		} catch (error) {
			console.error('Error al compartir:', error);
		}
		}


}

// http://localhost:8100/wish-list/RD7FvMLUMOOeUXcPr8JK;from=intercambio
// http://localhost:8100/wish-list/RD7FvMLUMOOeUXcPr8JK;from=intercambio
