import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, Router } from '@angular/router';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private afAuth: AngularFireAuth, private router: Router) {}

    canActivate(): Observable<boolean> {
		return this.afAuth.authState.pipe(
		  take(1), // Solo escucha el primer valor del observable
		  map((user) => {
			if (!!user) {
			  // Usuario está logueado
			  return true;
			} else {
			  // Usuario no está logueado, redirige al login
			  this.router.navigate(['/login']);
			  return false;
			}
		  })
		);
	  }

}
