import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonMenu } from '@ionic/angular';
import { FirebaseAuthService } from './shared/services/firebase-auth/firebase-auth.service';
import { WishListService } from './shared/services/wish-list/wish-list.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

	// SUBBCRIPTIONS
	private userSub_: Subscription;
	private defaultWishListSub_ : Subscription;

	@ViewChild('menu') menu: IonMenu;

	public appPages = [
		{ title: 'Intercambios', url: 'home', icon: 'home' },
		{ title: 'Lista de deseos', url: 'wish-list', icon: 'list' },
		{ title: 'Sugerencias', url: 'feedback', icon: 'heart' },
		{ title: 'Acerca de la app ', url: 'about-us', icon: 'help' }
	];
	public labels = ['Lista 1', 'Lista 2', 'Lista 3'];
	public user: any;
	public menuDisabled = true;
	constructor(public afs: FirebaseAuthService, public router: Router, public nav: Router, public auth: FirebaseAuthService, private wishListService: WishListService) { }

	ngOnInit() {
		this. userSub_ = this.auth.getUser().subscribe(user => {
			this.user = user;
			this.menuDisabled = false;
			this.defaultWishListSub_ =  this.wishListService.getDefaultWishList(user?.email).subscribe((data: any)=>{
				if(data.length > 0){
					this.appPages[1].url = `/wishlist/${data[0].defaultWishList}/menu`;
				}else{
					console.info("No hay una wish list");
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.userSub_.unsubscribe();
		this.defaultWishListSub_.unsubscribe();
	}

	logout() {
		this.menuDisabled = true;
		this.afs.logout();
		this.router.navigate(['login']);
	}
}
