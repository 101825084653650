import { Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import * as dayjs from 'dayjs'
import('dayjs/locale/es') // path must match with `i18n.language`

@Injectable({
	providedIn: 'root'
})
export class UtilsService {

	constructor(private alertCtrl: AlertController, private loadingCtrl: LoadingController) { }

	public loader: HTMLIonLoadingElement;

	async alertError(header: string, message: string) {

		const alert = this.alertCtrl.create({
			cssClass: 'my-custom-class',
			header: header,
			message: message,
			backdropDismiss: false,
			buttons: [
				{
					text: 'OK',
				},
			],
		});

		alert.then(al => {
			al.present();
		})
	}

	async showLoading(message = '') {
		this.loadingCtrl.create

		if(message === ''){
			this.loader = await this.loadingCtrl.create();
		} else {
			this.loader = await this.loadingCtrl.create({
				message: message
			});
		}
		this.loader.present();
	}

	hideLoading() {
		console.info("Hiding loader");
		setTimeout(() => {
			this.loader.dismiss();
		}, 500);
	}

	newFormatCurrencysInputs(event, cantidadStr){
		if (event.data === 'deleteContentBackward') {
			cantidadStr = cantidadStr.slice(0, -1);
		  } else {
			cantidadStr += event.key;
		  }
		  const rawNumber = parseFloat(cantidadStr.replace(/[^0-9]/g, '') || '0') / 100;
		  cantidadStr = `$${rawNumber.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
		  return {inputVal: cantidadStr, numberVal: rawNumber}
	}

	formatCurrencyInputs(inputEvent, cantidadMin: any, cantidadStr){
		const numeroIngresado = parseInt(inputEvent.data);
		cantidadMin = cantidadMin.toFixed(2);

		if(inputEvent.inputType === "deleteContentBackward"){
			if (cantidadStr.includes('.')) {
				const parts = cantidadStr.split('.');
				const integerPart = parts[0].replace(/[^0-9]/g, '');
				const decimalPart = parts[1].replace(/[^0-9]/g, '');
				cantidadStr = integerPart.slice(0, -1) + '.' + integerPart.slice(-1) + decimalPart;
				cantidadMin = parseFloat(cantidadStr.replace(/[^0-9.]/g, ''));
			}
		}



		if (!isNaN(numeroIngresado)) {
			cantidadMin = cantidadMin * 10 + numeroIngresado / 100;
			cantidadStr= new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
			}).format(cantidadMin);
		} else {
			if(inputEvent.target.value === '$0.0' || inputEvent.target.value === '$0.00'){
				cantidadStr= '';
				cantidadMin = 0;
			} else if ( /^[a-zA-Z]+$/.test(inputEvent.data)){
				cantidadStr = cantidadStr.replace(/[^0-9$.,]/g, '');
				cantidadMin = parseFloat(cantidadStr.replace(/[^0-9.]/g, ''));
			}else {
				let valorStr1 = inputEvent.target.value;
				valorStr1 = valorStr1.replace(/\D/g, '');

				const len = valorStr1.length;
				const parte1 = valorStr1.slice(0, len - 2);
				const parte2 = valorStr1.slice(len - 2);
				let valorStr = parte1 + '.' + parte2;

				cantidadMin = parseFloat(valorStr);
				cantidadStr= new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
				}).format(cantidadMin);
			}

		}
		return {inputVal: cantidadStr, numberVal: cantidadMin}
	}

	formatDate(date: string){
		dayjs.locale('es');
		date = dayjs(date, "YYYY-MM-DDTHH:mm:ss-Z").format('DD-MMMM-YYYY');
		const dateArr = date.split('-');
		return `${dateArr[0]} ${dateArr[1]} ${dateArr[2]}`;
	}

	clearAmazonLink(text){
		const urlRegex = /https?:\/\/[^\s]+/g;
		const urls = text.match(urlRegex);
		return urls[0];
	}

	setFocus(nextElement) {
		nextElement.setFocus();
	 }

}


