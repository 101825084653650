import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { user } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { GoogleAuthProvider } from 'firebase/auth';
import { Observable } from 'rxjs';
import { User } from '../../interfaces/User';
import { WishList } from '../../interfaces/Wishlist';
import { UserService } from '../users/user.service';
import { UtilsService } from '../Utils/Utils.service';
import { WishListService } from '../wish-list/wish-list.service';

@Injectable({
	providedIn: 'root'
})
export class FirebaseAuthService {

	public wishlist: WishList;

	constructor(private navCtrl: NavController, public afAuth: AngularFireAuth, public nav: Router, public userService: UserService, private wishListService: WishListService, private utils: UtilsService) { }

	async loginGoogle() {
		return this.afAuth.signInWithPopup(new GoogleAuthProvider()).then(data => {
			if (data.additionalUserInfo.isNewUser) {
				const user: User = {
					displayName: data.user.displayName,
					email: data.user.email,
					photoURL: data.user.photoURL,
					phoneNumber: data.user.phoneNumber,
					uid: data.user.uid,
					defaultWishList: ''
				}
				this.userService.createUser(user);
			}
			localStorage.setItem('userId', data.user.uid);
			localStorage.setItem('email', data.user.email);
			this.nav.navigate(['home']);
		});
	}

	getUser(): Observable<any> {
		return this.afAuth.user;
	}

	logout() {
		console.info("Entrando al metodo para loguear Log Out");
		this.afAuth.signOut();
		this.nav.navigate(['login']);
	}

	isLogin() {
		this.afAuth.authState.subscribe();
	}

	loginWithEmail(email: string, password: string) {
		this.afAuth.signInWithEmailAndPassword(email, password).then(data => {

			console.info("Login success");
			localStorage.setItem('userId', data.user.uid);
			localStorage.setItem('email', data.user.email);
			this.utils.hideLoading();
			this.navCtrl.navigateRoot(['home']);
			this.userService.getUserbyEmail(data.user.email).subscribe((data:any[])=>{
				if(data.length > 0){
					localStorage.setItem('display_name',data[0].displayName);
					localStorage.setItem('tutorial', data[0].tutorial );
					localStorage.setItem('wishlist', data[0].defaultWishList);
					localStorage.setItem('celular', data[0].phoneNumber);
					localStorage.setItem('calularValidated', data[0].phoneNumberValidated);
				} else{
					console.error("No se pudo obtener el nombre");
				}
			})

		}).catch(err=>{
			this.utils.hideLoading();
			console.error(err);
			this.utils.alertError('', 'Puede que tu email y contraseña esten equivocados, o que aun no tengas una cuenta.');
		})
	}

	registerEmail(email: string, password: string) {
		 return this.afAuth.createUserWithEmailAndPassword(email, password);
	}

	resetPassword(email: string){
		return this.afAuth.sendPasswordResetEmail(email);
	}



}
