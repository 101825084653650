import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { Wish, WishList } from '../../interfaces/Wishlist';
import { map, tap } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class WishListService {

	selectedWishList: WishList = {
		"ownerId": "Fd3ErTzubfSKAhkacP12Pq3q3K62",
		"wishes": [],
		"creacion": "test",
		"icon": "testIcon",
		"id": "XxAQf6R5fSNmOdgSTItQ",
		"publico": true,
		"email": "hiram.shock@gmail.com",
		"nombre": "default"
	}

	constructor(public firestore: AngularFirestore, public http: HttpClient) { }

	getOgDataAmazon(link) {
		return this.http.post(environment.api + '/getOgDataAmazon.php', { url: link }, {
			headers: {
				"Content-Type":
					"application/x-www-form-urlencoded; charset=UTF-8"
			}
		});
	}

	getOgDataMercadoLibre(link) {
		return this.http.post(environment.api + '/getOgDataMercadoLibre.php', { url: link }, {
			headers: {
				"Content-Type":
					"application/x-www-form-urlencoded; charset=UTF-8"
			}
		});
	}

	getOgDataOther(link) {
		return this.http.post(environment.api + '/getOgDataOther.php', { url: link }, {
			headers: {
				"Content-Type":
					"application/x-www-form-urlencoded; charset=UTF-8"
			}
		});
	}

	getOgDataElektra(link) {
		return this.http.post(environment.api + '/getOgDataElektra.php', { url: link }, {
			headers: {
				"Content-Type":
					"application/x-www-form-urlencoded; charset=UTF-8"
			}
		});
	}

	createWishList(wishlist: WishList) {
		return this.firestore.collection('WishLists').add(wishlist);
	}

	createWish(wish: Wish) {
		return this.firestore.collection('Wishes').add(wish)
	}

	getUserWishLists() {
		return this.firestore.collection('WishLists', ref => ref.where('ownerId', '==', localStorage.getItem('userId'))).snapshotChanges();
	}

	deleteUserWishLists(){
		return this.firestore.collection('WishLists', ref => ref.where('ownerId', '==', localStorage.getItem('userId'))).get();
	}

	getDefaultWishList(email: string) {
		return this.firestore.collection('Users', ref => ref.where('email', '==', email)).valueChanges() ;
	}

	getList(id) {
		return this.firestore.doc('WishLists/' + id).valueChanges();
	}

	// getWishes(id){
	//   return this.firestore.collection('Wishes', (ref) => ref.where('WishLists' , 'array-contains', id)).valueChanges()
	// }

	lookForWish(url: string) {

		return this.firestore.collection('Wishes', (ref) => ref.where('link', '==', url)).get();

		// .forEach(data=>{
		//   return data.docs[0].id;
		// })
	}

	lookForWish2(url: string) {
		return this.firestore.collection('Wishes', (ref) => ref.where('link', '==', url)).valueChanges();
	}

	updateWishById(wish) {
		let wishFs = this.firestore.doc('Wishes/' + wish.id);
		wishFs.update(wish.wish);
	}

	getTopTenWishes() {
		return this.firestore.collection('Wishes', ref => ref.orderBy('quantity', 'desc').limit(10)).valueChanges();
	}

	getOther10Wishes() {
		let lastVisible: any = null;

		return this.firestore.collection('Wishes', ref => {
			let query = ref.orderBy('quantity', 'desc').limit(10);
			if (lastVisible) {
				query = query.startAfter(lastVisible);
			}
			return query;
		}).get().pipe(
			tap(snapshot => {
				if (!snapshot.empty) {
					lastVisible = snapshot.docs[snapshot.docs.length - 1];
				}
			}),
			map(snapshot => snapshot.docs.map(doc => doc.data()))
		);
	}

	clearWishesInAllWishLists() {
		this.firestore.collection('WishLists').get().subscribe(snapshot => {
			snapshot.forEach(doc => {
				this.firestore.doc('WishLists/' + doc.id).update({ wishes: [] });
			});
		});
	}


}
