import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MindtechApiService } from '../shared/services/mindtech-api/mindtech-api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { Subscription } from 'rxjs';
register

@Component({
	selector: 'app-amazon-item-detail',
	templateUrl: './amazon-item-detail.page.html',
	standalone: true,
	imports: [CommonModule,
		FormsModule,
		IonicModule],
	styleUrls: ['./amazon-item-detail.page.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AmazonItemDetailPage implements OnInit {


	item = {
		title: "",
		price: '',
		permalink: "",
		thumbnail: "",
		description: "",
		pictures: []
	};

	selectedPictureURL = "";

	routeSubscription: Subscription;
	getItemSubscription: Subscription;

	constructor(private mindtechSrv: MindtechApiService, private route: ActivatedRoute) {
		this.routeSubscription = this.route.params.subscribe(params => {
			this.item.title = params.title;
			this.item.price = params.price;
			this.item.permalink = params.permalink;
			this.item.pictures = [params.mainImage, ...params.variantImages.split(",")];
			this.item.pictures = this.item.pictures.filter(picture => picture);
			this.item.description = params.description;
		});


	}

	ngOnInit() {

	}


	selectImage(num) {
		console.info("Seleccionando imagen");
	}

	getItemDetail(itemId) {
		this.getItemSubscription = this.mindtechSrv.getItem(itemId).subscribe((data: any) => {
			this.item = data.item;
			this.selectedPictureURL = this.item.pictures[0].secure_url;
		});
	}

	getNumber(num: string) {
		return num.split('.')[0].replace('$','');
	}

	getCents(num: string) {
		return num.split('.')[1];
	}

	ngOnDestroy(): void {
		this.routeSubscription ? this.routeSubscription.unsubscribe() : null;
		this.getItemSubscription ? this.getItemSubscription.unsubscribe() : null;
	}
}
