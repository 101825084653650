// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	firebase: {
		projectId: 'intercambio-91ac8',
		appId: '1:576217653961:web:899b5576c5e665adc6a318',
		databaseURL: 'https://intercambio-91ac8.firebaseio.com',
		storageBucket: 'intercambio-91ac8.appspot.com',
		locationId: 'us-central',
		apiKey: 'AIzaSyBJyfY0RazsIX19mw_IDDRFxtfW8HLJ54o',
		authDomain: 'intercambio-91ac8.firebaseapp.com',
		messagingSenderId: '576217653961',
		measurementId: 'G-FV9ZS5DHV0',
	},
	// api: 'http://localhost/',
	api: 'https://www.wish-box.com.mx/api',
	production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
